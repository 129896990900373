import { get, post, downloadG } from '@/utils/request';

// -------接口参数约束------

export interface bindAppParam {
    bind?:any
    appId?:any
    apiId?:any
}

export interface loadBindParam {
    current?:any
    size?:any
    appId?:any
    keyword?:any
}
// -------接口列------

/**
 * [后台]加载绑定信息
 * @notes 
 */
export function loadBind(param:loadBindParam) {
    return get('/system/appPermission/loadBind', param);
}

/**
 * [后台]绑定应用
 * @notes 
 */
export function bindApp(param:bindAppParam) {
    return post('/system/appPermission/bindApp', param);
}

