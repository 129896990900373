import { get, post, downloadG } from '@/utils/request';

// -------接口参数约束------

export interface removeAppParam {
    appId?:any
}

export interface addAppParam {
    description?:any
    platform?:any
}

export interface updateAppParam {
    appId?:any
    description?:any
    version?:any
    platform?:any
}

export interface changeStatusParam {
    appId?:any
}

export interface pageParam {
    current?:any
    size?:any
    keyword?:any
}
// -------接口列------

/**
 * [后台]加载所有可用的应用信息
 * @notes 
 */
export function loadEnabled() {
    return get('/system/appConfig/loadEnabled');
}

/**
 * [后台]添加APP应用
 * @notes 
 */
export function addApp(param:addAppParam) {
    return post('/system/appConfig/addApp', param);
}

/**
 * [后台]删除APP应用
 * @notes 
 */
export function removeApp(param:removeAppParam) {
    return get('/system/appConfig/removeApp', param);
}

/**
 * [后台]变更保存APP应用
 * @notes 
 */
export function updateApp(param:updateAppParam) {
    return post('/system/appConfig/updateApp', param);
}

/**
 * [后台]API分页
 * @notes 
 */
export function page(param:pageParam) {
    return get('/system/appConfig/page', param);
}

/**
 * [后台]改变状态
 * @notes 
 */
export function changeStatus(param:changeStatusParam) {
    return get('/system/appConfig/changeStatus', param);
}

