
import { addApp, updateApp } from '@/api/system/appConfig';
import { cloneDeep } from '@/utils/common';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
    name: 'ChangeAppConfig'
})
export default class ChangeAppConfig extends Vue {
    @Prop({ required: true, default: false }) dialogUpdateVisible!: boolean;
    @Prop({ required: true, default: false }) readonly isEdit!:boolean;
    @Prop({ 
      required: false, 
      default: () => {
        return {
            appId: null,
            platform: null,
            description: null,
            version: null,
            appKey: null,
            appSecret: null
        }
      }
    }) 
    readonly infoParams!: any;
    
    get dialogVisible(){
      return this.dialogUpdateVisible;
    }

    set dialogVisible(val){
      this.$emit('update:dialogUpdateVisible', val)
    }
     
    formParam:any = {}

    get infoTitle(){
        if(this.isEdit){
            return "编辑应用-【" + this.formParam.platform + "】";
        }else{
            return "添加应用";
        }
    }

    @Emit("getList")
    getList(){}

    handleUpdate(){
        if(this.isEdit){
            updateApp(this.formParam).then(res => {
                this.$message({
                    type: 'success',
                    message: '操作成功!'
                });
                this.dialogVisible = false
                this.getList();
            })
        }else{
            addApp(this.formParam).then(res=>{
                this.$message({
                    type: 'success',
                    message: '操作成功!'
                });
                this.dialogVisible = false
                this.getList();
            })
        }
    }

    @Watch("infoParams")
    infoParamsChange(){
        this.formParam = cloneDeep(this.infoParams)
    }
}
