
import { changeStatus, page, removeApp } from '@/api/system/appConfig'
import { Component,Vue } from 'vue-property-decorator'
import AppPermissionBind from './components/AppPermissionBind.vue'
import JtPagination from '@/components/JtPagination/index.vue'
import ChangeAppConfig from './components/ChangeAppConfig.vue'

@Component({
    name: 'AppConfig',
    components: {
      AppPermissionBind,
      ChangeAppConfig,
      JtPagination
    }
})
export default class extends Vue {
  listLoading = true
  total = 0
  list = []
  listQuery = {
    current: 1,
    size: 10,
    keyword: null
  }
  dialogVisible = false
  dialogVisibleChange = false
  isEdit = false
  infoParams:any = {}
  changeParams:any = {}

  mounted() {
      this.getList()
  }
  //初始加载数据
  handleFilter() {
    this.getList()
  }

  //加载api数据
  getList(){
    this.listLoading = true
    page(this.listQuery).then(response => {
        this.list = response.data.rows
        this.total = response.data.total
        // Just to simulate the time of the request
        setTimeout(() => {
          this.listLoading = false
        }, 500)
      })
  }

  handleRemoveApp(row:any){
    this.$confirm('此操作将删除当前角色, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      removeApp(row).then(response => {
        this.handleFilter()
        this.$message({
            type: 'success',
            message: '操作成功!'
          });
      }).catch(()=>{})
    }).catch(() => {
      this.$message({
            type: 'info',
            message: '已取消当前操作'
      });      
    });
  }

  //绑定权限
  handlePermission(row:any){
    this.infoParams = row
    this.dialogVisible = true
  }
  //添加
  handleAddApp(){
    this.isEdit = false
    this.changeParams = {}
    this.dialogVisibleChange = true
  }
    //编辑
  handleEditApp(row:any){
    this.isEdit = true
    this.changeParams = row
    this.dialogVisibleChange = true
  }

  changeStatus(row:any) {
    this.$confirm(row.enable ? "是否要禁用？" : "是否要启用？", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning"
    }).then(() => {
      changeStatus({ appId: row.appId }).then(() => {
        this.$message.success('操作成功');
        this.getList();
      })
    });
  }

  copyText(row:any,column:any,cell:any,event:any){
    const save = function (e:any){
      e.clipboardData.setData('text/plain',event.target.innerText);
      e.preventDefault();
    }
    document.addEventListener('copy',save);
    document.execCommand('copy');
    this.$message.success('复制成功');
  }
}
