
import { bindApp, loadBind } from '@/api/system/appPermission';
import JtPagination from '@/components/JtPagination/index.vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
  name: 'AppPermissionBind',
  components: {
    JtPagination
  }
})
export default class AppPermissionBind extends Vue {
  @Prop({ required: true, default: false }) dialogPermissionVisible!: boolean;
  @Prop({ required: true }) readonly roleInfo!: any;

  listLoading = true
  total = 0
  list = []
  listQuery = {
    current: 1,
    size: 10,
    appId: null,
    keyword: null
  }
  //加载api数据
  loadBindList() {
    this.listLoading = true
    loadBind(this.listQuery).then(response => {
      this.list = response.data.rows
      this.total = response.data.total
      // Just to simulate the time of the request
      setTimeout(() => {
        this.listLoading = false
      }, 500)
    })
  }

  get dialogVisible() {
    return this.dialogPermissionVisible;
  }

  set dialogVisible(val) {
    this.$emit('update:dialogPermissionVisible', val)
  }

  //初始加载数据
  handleFilter() {
    this.listQuery.current = 1
    this.loadBindList()
  }

  bindPermission(row: any, bind: boolean) {
    bindApp({
      bind,
      appId: this.listQuery.appId,
      apiId: row.id
    }).then(() => {
      row.selected = bind
      this.$message({
        type: 'success',
        message: '操作成功!'
      });
    }).catch(() => {
    })
  }

  @Watch("roleInfo")
  infoParamsChange() {
    this.listQuery.appId = this.roleInfo.appId
    this.loadBindList()
  }
}
